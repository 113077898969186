import InflationPage from 'components/pages/inflation/InflationPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Pokonaj inflację'
			keywords='pokonaj inflacje, inflacja, wysoka inflacja, rosnąca inflacja, efekt inflacji, utrata wartości'
			description='Wykorzystując wolny kapitał lub depozyty możesz szybko pokonać inflację. Uruchom program wcześniejszych płatności na platformie Finteq i walcz z inflacją'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<InflationPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
