import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function Seo({ title, description, keywords, contentType, image, alt, locale, lang }) {
	const seoTitle = title;
	const seoKeywords = keywords;
	const seoDescription = description;
	const seoImage = image || `${process.env.GATSBY_API_SEO_URL}/files/finteq_og_image.png`;
	const seoAlt = alt || title;

	return (
		<Helmet
			defaultTitle={seoTitle}
			htmlAttributes={{ lang }}
		>
			<title>{seoTitle}</title>
			<meta property='og:site_name' content='Finteq' />
			<meta property='og:type' content={contentType} />
			<meta property='og:title' content={seoTitle} />
			<meta property='og:description' content={seoDescription} />
			<meta property='og:image' content={seoImage} />
			<meta property='og:image' content={seoAlt} />
			<meta property='og:locale' content={locale} />
			<meta name='keywords' content={seoKeywords} />
			<meta name='description' content={seoDescription} />
			<meta name='image' content={seoImage} />
			<meta name='twitter:card' content='summary' />
			<meta name='twitter:site' content='@finteq_pl' />
			<meta name='twitter:title' content={seoTitle} />
			<meta name='twitter:description' content={seoDescription} />
			<meta name='twitter:image' content={seoImage} />
			<meta name='twitter:image:alt' content={seoAlt} />
		</Helmet>
	);
}

Seo.defaultProps = {
	contentType: 'website', // Use 'article' for articles and 'website' for the rest of your pages.
	url: null, // basically tells the FB scraper "ignore anything on this page, and scrape this url instead"
	meta: []
};

Seo.propTypes = {
	title: PropTypes.string.isRequired,
	keywords: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	lang: PropTypes.string.isRequired, // locales: 'pl-PL',
	locale: PropTypes.string.isRequired, // locale: 'pl_PL',
	meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
